<template>
  <div>
    <breadcrumbs :items="breadcrumbs" />
    <Loader :visible="loading" />
    <v-container fluid class="pa-0" v-if="!loading">
      <v-row class="ma-0" :class="desktop ? 'pt-16' : ''" justify="center">
        <v-col class="pa-0 ma-0" style="max-width: 1000px" align="center">
          <v-img :src="require('@/assets/img/encuesta.svg')" class="rounded mb-8" contain max-width="450px" />
          <div style="font-size: 25px" class="mb-8 mt-4 form-title tway-gray-dark--text">
            Diagnóstico de Transformación Digital
          </div>
          <div class="tway-header-2 tway-gray-dark--text mb-4">
            Para activar, acelerar y facilitar la Transformación, el primer paso es diagnosticar el status digital de tu
            compañía. Aquí comienza el proceso que recalibra tu negocio y ajusta su propuesta de valor a las exigencias
            de la Industria 4.0.
          </div>
          <div class="tway-header-2 tway-gray-dark--text mb-4">
            Con este Índice, inicias el camino hacia la competitividad necesaria en la Economía Digital.
          </div>
          <div class="tway-header-2 tway-gray-dark--text mb-12">No tardarás más de 15 minutos.</div>
          <v-row class="ma-0" justify="center">
            <v-btn depressed rounded color="tway-violet" class="button-text white--text" :to="{ name: 'survey' }">
              <span v-if="this.getPercentage == 0">Completar encuesta</span>
              <span v-else>Continuar encuesta</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Breadcrumbs from "@/components/Breadcrumbs";
import dtService from "@/services/dtService";

import currentUserService from "@/services/currentUserService";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Loader,
    Breadcrumbs,
  },
  name: "Form",

  data: () => ({
    breadcrumbs: [
      {
        text: "Inicio",
        disabled: false,
        exact: true,
        to: {
          name: "home",
        },
      },
      {
        text: "Encuesta IMTD",
        disabled: true,
        exact: true,
        to: {
          name: "dti-form",
        },
      },
    ],
    clientType: "",
    firstDti: null,
    percentage: {
      value: 0,
      index: true,
    },
    dti: {
      score: 0,
    },
    loading: true,
  }),

  async created() {
    this.firstDti = this.$route.params.firstDti ? this.$route.params.firstDti : false;

    this.setCompanyProfile();
    this.user = await currentUserService.currentUserData();
    const questions = await dtService.getSurveyComplete("a123", this.user.id);
    this.SET_PERCENTAGE(Math.floor((questions.answered / questions.total) * 100));

    if (this.getPercentage != 100) {
      this.loading = false;
    } else {
      this.getDti(this.user.id, questions.surveyId, this.firstDti);
    }
  },
  methods: {
    ...mapActions("UserStore", ["SET_PERCENTAGE", "setCompanyProfile"]),
    ...mapActions("ImtdStore", ["setRecommendations"]),
    async getDti(userId, surveyId) {
      this.dti = await dtService.getDti(userId, surveyId, this.firstDti);
      this.percentage.value = (this.dti.score * 10).toFixed(0);
      this.loading = false;
    },
    goTo(to) {
      this.$router.push(to);
    },
  },
  computed: {
    ...mapGetters("UserStore", ["getPercentage", "getCompany", "getClientId"]),
    ...mapGetters("ImtdStore", ["getScore"]),
    ...mapGetters("authentication", ["getCorporative"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  font-weight: 400 !important;
}
.tway-header-2 {
  font-weight: 300 !important;
  font-size: 16px;
  line-height: 25px !important;
  font-family: "Roboto Condensed", sans-serif !important;
}
.title-idx-subtitle {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  text-align: start;
  color: #7319d5;
}
.body-idx-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: start;
  color: rgba(#000, 0.8);
}
.step-text {
  max-width: 36em;
}
.loading-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.pertentage-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #7319d5;
}
</style>
